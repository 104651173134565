/* eslint-disable no-underscore-dangle */
import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import useAuth from "hooks/useAuth";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Card,
  CardHeader,
  CardContent,
  Button,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";

// project imports
import { useDispatch, useSelector } from "store";
import { gridSpacing } from "store/constant";
import DashCountCard from "ui-component/cards/DashCountCard";
import ProjectsTableCard from "./ProjectsTableCard";
import { useGetDeveloperProjects } from "api/project";
import AllProjectsMap from "./AllProjectsMap";
import CountryDistributionChartCard from "./CountryDistributionChartCard";
import ProjectsByTypeChartCard from "./ProjectsByTypeChartCard";

// assets
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DescriptionTwoToneIcon from "@mui/icons-material/DescriptionTwoTone";
import WalletIcon from "@mui/icons-material/Wallet";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import Loader from "ui-component/Loader";

// ==============================|| DEVELOPER DASHBOARD ||============================== //

const Dashboard = () => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const { user } = useAuth();
  const { projects, isLoading: projectsLoading } = useGetDeveloperProjects(
    user.email
  );
  const [selectedMilestone, setSelectedMilestone] = useState("All");
  const [selectedType, setSelectedType] = useState("All");
  const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
  const matchDownMD = useMediaQuery(theme.breakpoints.down("lg"));
  // const [loading, setLoading] = useState(true);
  const spacingMD = matchDownMD ? 1 : 1.5;

  // Filter projects by both milestone and type
  const filteredProjects = projects?.filter(
    (project) =>
      (selectedMilestone === "All" ||
        project.milestone === selectedMilestone) &&
      (selectedType === "All" || project.projectType === selectedType) &&
      project?.organizationDetails?.onboardingType === "Development"
  );

  // Handler for milestone changes
  const handleMilestoneChange = (milestone) => {
    setSelectedMilestone(milestone);
  };

  // Handler for project type changes
  const handleTypeChange = (type) => {
    setSelectedType(type);
  };

  if (projectsLoading) {
    return <Loader />;
  }

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          spacing={matchDownMD ? 0.5 : 2}
        >
          <Grid item>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="h4">Dashboard</Typography>
              <IconButton size="large">
                <ArrowForwardIosIcon
                  sx={{
                    width: "0.875rem",
                    height: "0.875rem",
                    fontWeight: 500,
                    color: "grey.500",
                  }}
                />
              </IconButton>
            </Stack>
          </Grid>
          <Grid item>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={matchDownSM ? 0.5 : spacingMD}
            >
              {/* <Button
                variant="outlined"
                color="primary"
                component={Link}
                to="/proponent/wallet"
              >
                Wallet
              </Button> */}
              <Button
                variant="contained"
                color="secondary"
                component={Link}
                to="/developer/projects/list"
              >
                My Projects
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Divider sx={{ borderColor: "grey.400" }} />
      </Grid>

      <Grid item xs={12} lg={4} md={6}>
        <DashCountCard
          primary="Projects Developed"
          secondary={
            projects?.filter(
              (project) =>
                project?.organizationDetails?.onboardingType === "Development"
            ).length
          }
          iconPrimary={DescriptionTwoToneIcon}
          color={theme.palette.secondary.main}
        />
      </Grid>

      <Grid item xs={12} lg={4} md={6}>
        <DashCountCard
          primary="Total Credits Issued"
          secondary="0"
          iconPrimary={AccountBalanceWalletIcon}
          color={theme.palette.primary.main}
        />
      </Grid>

      <Grid item xs={12} lg={4} md={6}>
        <DashCountCard
          primary="Total Credits Retired"
          secondary="0"
          iconPrimary={WalletIcon}
          color={theme.palette.purple.main}
        />
      </Grid>

      {/* <Grid item xs={6}>
        <CountryDistributionChartCard chartData={projectsChartData} />
      </Grid>

      <Grid item xs={6}>
        <ProjectsByTypeChartCard chartData={projectsTypeChartData} />
      </Grid> */}

      <Grid item xs={12}>
        <ProjectsTableCard
          title="Developed Projects"
          projects={filteredProjects}
          onMilestoneChange={handleMilestoneChange}
          onTypeChange={handleTypeChange}
        />
      </Grid>

      <Grid item xs={12}>
        <Card
          style={{ height: "100%", overflow: "hidden", position: "relative" }}
        >
          <CardHeader title="Developed Projects Map" />
          <CardContent
            style={{
              padding: 0,
              height: "calc(100% - 56px)",
              position: "relative",
            }}
          >
            <AllProjectsMap projects={filteredProjects} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Dashboard;
