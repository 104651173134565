import PropTypes from "prop-types";
import { useContext } from "react";

// material-ui
import {
  Avatar,
  Box,
  Card,
  Grid,
  MenuItem,
  Popper,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { styled, useTheme } from "@mui/material/styles";

// third-party
import PopupState, { bindPopper, bindToggle } from "material-ui-popup-state";

// project imports
import Transitions from "ui-component/extended/Transitions";

// assets
import { shouldForwardProp } from "@mui/system";
import { IconPresentation } from "@tabler/icons";
import ProjectContext from "contexts/FinanceProjectContext";

// styles
const PopperStyle = styled(Popper, { shouldForwardProp })(({ theme }) => ({
  zIndex: 1100,
  width: "99%",
  top: "-55px !important",
  padding: "0 12px",
  [theme.breakpoints.down("sm")]: {
    padding: "0 10px",
  },
}));

const OutlineInputStyle = styled(TextField, { shouldForwardProp })(
  ({ theme }) => ({
    width: 434,
    marginLeft: 16,
    paddingLeft: 16,
    paddingRight: 16,
    "& input": {
      background: "transparent !important",
      paddingLeft: "4px !important",
    },
    [theme.breakpoints.down("lg")]: {
      width: 250,
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginLeft: 4,
      background:
        theme.palette.mode === "dark" ? theme.palette.dark[800] : "#fff",
    },
  })
);

const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(
  ({ theme }) => ({
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    background:
      theme.palette.mode === "dark"
        ? theme.palette.dark.main
        : theme.palette.secondary.light,
    color:
      theme.palette.mode === "dark"
        ? theme.palette.secondary.main
        : theme.palette.secondary.dark,
    "&:hover": {
      background:
        theme.palette.mode === "dark"
          ? theme.palette.secondary.main
          : theme.palette.secondary.dark,
      color:
        theme.palette.mode === "dark"
          ? theme.palette.secondary.light
          : theme.palette.secondary.light,
    },
  })
);

// ==============================|| SEARCH INPUT - MOBILE||============================== //

const MobileSearch = ({ value, setValue, popupState }) => {
  const { project, setProject, projects } = useContext(ProjectContext);

  return (
    <OutlineInputStyle
      id="input-select-project-header"
      value={project?._id}
      defaultValue=""
      onChange={(e) => setProject(e.target.value)}
      select
      placeholder="Select Project"
      aria-describedby="search-helper-text"
      inputProps={{ "aria-label": "weight" }}
    >
      <MenuItem value="" disabled>
        Select Project
      </MenuItem>
      {projects.map((project) => (
        <MenuItem key={project._id} value={project._id}>
          {project.projectName}
        </MenuItem>
      ))}
    </OutlineInputStyle>
  );
};

MobileSearch.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func,
  popupState: PopupState,
};

// ==============================|| SEARCH INPUT ||============================== //

const SelectProjectSection = () => {
  const theme = useTheme();
  const { project, setProject, projects, projectsLoading, projectsError } =
    useContext(ProjectContext);

  if (projectsLoading) {
    return <CircularProgress sx={{ ml: 5 }} />;
  }

  if (projectsError) {
    return <Typography color="black">Error...</Typography>;
  }

  return (
    <>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <PopupState variant="popper" popupId="demo-popup-popper">
          {(popupState) => (
            <>
              <Box sx={{ ml: 2 }}>
                <HeaderAvatarStyle
                  variant="rounded"
                  {...bindToggle(popupState)}
                >
                  <IconPresentation stroke={1.5} size="19px" />
                </HeaderAvatarStyle>
              </Box>
              <PopperStyle {...bindPopper(popupState)} transition>
                {({ TransitionProps }) => (
                  <>
                    <Transitions
                      type="zoom"
                      {...TransitionProps}
                      sx={{ transformOrigin: "center left" }}
                    >
                      <Card
                        sx={{
                          background:
                            theme.palette.mode === "dark"
                              ? theme.palette.dark[900]
                              : "#fff",
                          [theme.breakpoints.down("sm")]: {
                            border: 0,
                            boxShadow: "none",
                          },
                        }}
                      >
                        <Box sx={{ p: 2 }}>
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Grid item xs>
                              <MobileSearch
                                value={project}
                                setValue={setProject}
                                popupState={popupState}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Card>
                    </Transitions>
                  </>
                )}
              </PopperStyle>
            </>
          )}
        </PopupState>
      </Box>
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <OutlineInputStyle
          id="input-select-project-header"
          value={project?._id || ""}
          defaultValue=""
          onChange={(e) => setProject(e.target.value)}
          select
          placeholder="Select Project"
          aria-describedby="search-helper-text"
          inputProps={{ "aria-label": "weight" }}
        >
          <MenuItem value="" disabled>
            Select Project
          </MenuItem>
          {projects.map((p) => (
            <MenuItem key={p._id} value={p._id}>
              {p.projectName}
            </MenuItem>
          ))}
        </OutlineInputStyle>
      </Box>
    </>
  );
};

export default SelectProjectSection;
