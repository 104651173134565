import { lazy } from "react";

// project imports
import Loadable from "ui-component/Loadable";
import MinimalLayout from "layout/MinimalLayout";
import CommunityLayout from "layout/CommunityLayout";
import { ProjectProvider } from "contexts/ProjectContext";

// views 
const SamplePage = Loadable(lazy(() => import("views/sample-page")));
const FPICFAQsPage = Loadable(lazy(() => import("views/community-portal/fpic/faqs")));
const ReportsListPage = Loadable(lazy(() => import("views/community-portal/archive/list")));
const ReportsCreatePage = Loadable(lazy(() => import("views/community-portal/archive/create")));
const CommunityPortalHome = Loadable(lazy(() => import("views/community-portal/home")));
const ReportsEditPage = Loadable(lazy(() => import("views/community-portal/archive/edit")));
const ReportsViewPage = Loadable(lazy(() => import("views/community-portal/archive/view")));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const CommunityRoutes = {
  path: "/community-portal",
  element: (
    <ProjectProvider>
      <CommunityLayout />
    </ProjectProvider>
  ),
  children: [
    {
      path: "home",
      element: <CommunityPortalHome />,
    },
    {
      path: "fpic/faqs",
      element: <FPICFAQsPage />,
    },
    {
      path: "archive/reports/create",
      element: <ReportsCreatePage />,
    },
    {
      path: "archive/reports/list",
      element: <ReportsListPage />,
    },
    {
      path: "archive/reports/edit/:id",
      element: <ReportsEditPage />,
    },
    {
      path: "archive/reports/view/:id",
      element: <ReportsViewPage />,
    },
  ],
};

export default CommunityRoutes;
