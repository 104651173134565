// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
  IconApps,
  IconUserCheck,
  IconBasket,
  IconMessages,
  IconLayoutKanban,
  IconMail,
  IconCalendar,
  IconNfc,
  IconClipboardList,
  IconTrees,
  IconHeartHandshake,
  IconDashboard,
  IconUsers,
  IconAlignBoxLeftMiddle,
  IconBoxMultiple,
  IconWallet,
  IconPremiumRights,
  IconCreditCard,
  IconFilePencil,
  IconReport,
  IconUserPlus,
  IconFiles,
  IconBuildingStore,
  IconUpload,
  IconFileText,
  IconListDetails,
} from "@tabler/icons";

// constant
const icons = {
  IconApps,
  IconUserCheck,
  IconBasket,
  IconMessages,
  IconLayoutKanban,
  IconMail,
  IconCalendar,
  IconNfc,
  IconClipboardList,
  IconTrees,
  IconHeartHandshake,
  IconDashboard,
  IconUsers,
  IconAlignBoxLeftMiddle,
  IconBoxMultiple,
  IconWallet,
  IconPremiumRights,
  IconCreditCard,
  IconFilePencil,
  IconReport,
  IconUserPlus,
  IconFiles,
  IconBuildingStore,
  IconUpload,
  IconFileText,
  IconListDetails,
};

// ==============================|| BUYER MENU ITEMS ||============================== //

const buyer = {
  id: "buyer",
  title: <FormattedMessage id="buyer" defaultMessage="Buyer" />,
  icon: icons.IconApps,
  type: "group",
  roles: ["buyer"],
  children: [
    {
      id: "buyer_dashboard",
      title: <FormattedMessage id="Dashboard" defaultMessage="Dashboard" />,
      type: "item",
      url: "/dashboard",
      icon: icons.IconDashboard,
      breadcrumbs: false,
      roles: ["buyer"],
    },
    {
      id: "marketplace",
      title: <FormattedMessage id="Marketplace" defaultMessage="Marketplace" />,
      type: "collapse",
      breadcrumbs: false,
      icon: icons.IconTrees,
      children: [
        {
          id: "spot_marketplace",
          title: (
            <FormattedMessage
              id="Spot Marketplace"
              defaultMessage="Spot Marketplace"
            />
          ),
          type: "item",
          icon: icons.IconHeartHandshake,
          url: "/buyer/marketplace/spotMarketplace",
          breadcrumbs: false,
          roles: ["buyer"],
        },
        {
          id: "upcoming_projects",
          title: (
            <FormattedMessage
              id="Upcoming Projects"
              defaultMessage="Upcoming Projects"
            />
          ),
          type: "item",
          url: "/buyer/marketplace/upcomingProjects",
          icon: icons.IconClipboardList,
          breadcrumbs: false,
          roles: ["buyer"],
        },
      ],
    },
    // {
    //   id: "carbon_credits",
    //   title: (
    //     <FormattedMessage id="CarbonCredits" defaultMessage="Carbon Credits" />
    //   ),
    //   type: "collapse",
    //   breadcrumbs: false,
    //   icon: icons.IconBuildingStore,
    //   children: [
    //     {
    //       id: "myCredits",
    //       title: (
    //         <FormattedMessage
    //           id="My Carbon Credits"
    //           defaultMessage="My Carbon Credits"
    //         />
    //       ),
    //       type: "item",
    //       icon: icons.IconUpload,
    //       url: "/buyer/carbonCredits",
    //       breadcrumbs: false,
    //       roles: ["Buyer"],
    //     },
    //   ],
    // },
    {
      id: "payment_details",
      title: (
        <FormattedMessage
          id="Payment Details"
          defaultMessage="Purchase Requests"
        />
      ),
      type: "collapse",
      breadcrumbs: false,
      icon: icons.IconBuildingStore,
      children: [
        {
          id: "Payment Details",
          title: (
            <FormattedMessage
              id="Payment Details"
              defaultMessage="Purchase Details"
            />
          ),
          type: "item",
          icon: icons.IconListDetails,
          url: "/buyer/paymentDetails",
          breadcrumbs: false,
          roles: ["buyer"],
        },
      ],
    },
  ],
};

export default buyer;
