import PropTypes from "prop-types";
import { useContext, useState, useEffect } from "react";

// material-ui
import { useTheme, styled } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Card,
  CircularProgress,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Popper,
  TextField,
  Typography,
} from "@mui/material";

// third-party
import PopupState, { bindPopper, bindToggle } from "material-ui-popup-state";

// project imports
import Transitions from "ui-component/extended/Transitions";
import LoadingState from "ui-component/query-status/Loading";

// assets
import {
  IconAdjustmentsHorizontal,
  IconPresentation,
  IconX,
} from "@tabler/icons";
import { shouldForwardProp } from "@mui/system";
import ProjectContext from "contexts/ProjectContext";

// styles
const PopperStyle = styled(Popper, { shouldForwardProp })(({ theme }) => ({
  zIndex: 1100,
  width: "99%",
  top: "-55px !important",
  padding: "0 12px",
  [theme.breakpoints.down("sm")]: {
    padding: "0 10px",
  },
}));

const OutlineInputStyle = styled(TextField, { shouldForwardProp })(
  ({ theme }) => ({
    width: 434,
    marginLeft: 16,
    paddingLeft: 16,
    paddingRight: 16,
    "& input": {
      background: "transparent !important",
      paddingLeft: "4px !important",
    },
    [theme.breakpoints.down("lg")]: {
      width: 250,
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginLeft: 4,
      background:
        theme.palette.mode === "dark" ? theme.palette.dark[800] : "#fff",
    },
  })
);

const HeaderAvatarStyle = styled(Avatar, { shouldForwardProp })(
  ({ theme }) => ({
    ...theme.typography.commonAvatar,
    ...theme.typography.mediumAvatar,
    background:
      theme.palette.mode === "dark"
        ? theme.palette.dark.main
        : theme.palette.secondary.light,
    color:
      theme.palette.mode === "dark"
        ? theme.palette.secondary.main
        : theme.palette.secondary.dark,
    "&:hover": {
      background:
        theme.palette.mode === "dark"
          ? theme.palette.secondary.main
          : theme.palette.secondary.dark,
      color:
        theme.palette.mode === "dark"
          ? theme.palette.secondary.light
          : theme.palette.secondary.light,
    },
  })
);

// ==============================|| SEARCH INPUT - MOBILE||============================== //

const MobileSearch = ({ value, setValue, popupState }) => {
  const theme = useTheme();

  return (
    <OutlineInputStyle
      id="input-search-header"
      type="select"
      value={value}
      onChange={(e) => setValue(e.target.value)}
      placeholder="project"
      startAdornment={
        <InputAdornment position="start">
          <IconPresentation
            stroke={1.5}
            size="16px"
            color={theme.palette.grey[500]}
          />
        </InputAdornment>
      }
      endAdornment={
        <InputAdornment position="end">
          <HeaderAvatarStyle variant="rounded">
            <IconAdjustmentsHorizontal stroke={1.5} size="20px" />
          </HeaderAvatarStyle>
          <Box sx={{ ml: 2 }}>
            <Avatar
              variant="rounded"
              sx={{
                ...theme.typography.commonAvatar,
                ...theme.typography.mediumAvatar,
                background:
                  theme.palette.mode === "dark"
                    ? theme.palette.dark.main
                    : theme.palette.orange.light,
                color: theme.palette.orange.dark,
                "&:hover": {
                  background: theme.palette.orange.dark,
                  color: theme.palette.orange.light,
                },
              }}
              {...bindToggle(popupState)}
            >
              <IconX stroke={1.5} size="20px" />
            </Avatar>
          </Box>
        </InputAdornment>
      }
      aria-describedby="search-helper-text"
      inputProps={{ "aria-label": "weight" }}
    />
  );
};

MobileSearch.propTypes = {
  value: PropTypes.string,
  setValue: PropTypes.func,
  popupState: PopupState,
};

// ==============================|| SELECT PROJECT ||============================== //

const SelectProjectSection = () => {
  const theme = useTheme();
  const [loading, setLoading] = useState(true);
  const { project, setProject, projects, projectsLoading, projectsError } =
    useContext(ProjectContext);

  const filteredProjects =
    projects?.filter((project) => project?.projectType !== "Energy Demand") ||
    [];

  const [selectedProject, setSelectedProject] = useState(
    () =>
      localStorage.getItem("selectedProject") ||
      (filteredProjects.length > 0 ? filteredProjects[0]._id : "")
  );

  useEffect(() => {
    if (selectedProject && projects) {
      const selectedProj = projects.find((p) => p._id === selectedProject);
      setProject(selectedProj);
      setLoading(false);
    }
  }, [selectedProject, projects, setProject]);

  const handleProjectChange = (event) => {
    const selectedProjectId = event.target.value;
    setSelectedProject(selectedProjectId);
    const selectedProj = projects.find((p) => p._id === selectedProjectId);
    setProject(selectedProj);
    localStorage.setItem("selectedProject", selectedProjectId);
  };

  if (projectsLoading || loading) {
    return <Typography>Loading...</Typography>;
  }

  if (projectsError) {
    return <Typography color="warning">Error...</Typography>;
  }

  return (
    <>
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <PopupState variant="popper" popupId="demo-popup-popper">
          {(popupState) => (
            <>
              <Box sx={{ ml: 2 }}>
                <HeaderAvatarStyle
                  variant="rounded"
                  {...bindToggle(popupState)}
                >
                  <IconPresentation stroke={1.5} size="19px" />
                </HeaderAvatarStyle>
              </Box>
              <PopperStyle {...bindPopper(popupState)} transition>
                {({ TransitionProps }) => (
                  <>
                    <Transitions
                      type="zoom"
                      {...TransitionProps}
                      sx={{ transformOrigin: "center left" }}
                    >
                      <Card
                        sx={{
                          background:
                            theme.palette.mode === "dark"
                              ? theme.palette.dark[900]
                              : "#fff",
                          [theme.breakpoints.down("sm")]: {
                            border: 0,
                            boxShadow: "none",
                          },
                        }}
                      >
                        <Box sx={{ p: 2 }}>
                          <Grid
                            container
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            <Grid item xs>
                              <MobileSearch
                                value={selectedProject}
                                setValue={handleProjectChange}
                                popupState={popupState}
                              />
                            </Grid>
                          </Grid>
                        </Box>
                      </Card>
                    </Transitions>
                  </>
                )}
              </PopperStyle>
            </>
          )}
        </PopupState>
      </Box>
      <Box sx={{ display: { xs: "none", md: "block" } }}>
        <OutlineInputStyle
          id="input-select-project-header"
          value={selectedProject || ""}
          onChange={handleProjectChange}
          select
          placeholder="Select Project"
          aria-describedby="search-helper-text"
          inputProps={{ "aria-label": "weight" }}
        >
          <MenuItem value="" disabled>
            Select Project
          </MenuItem>
          {filteredProjects.map((project) => (
            <MenuItem key={project._id} value={project._id}>
              {project.projectName}
            </MenuItem>
          ))}
        </OutlineInputStyle>
      </Box>
    </>
  );
};

export default SelectProjectSection;
