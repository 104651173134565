import { lazy } from "react";

// project imports
import Loadable from "ui-component/Loadable";
import AdminLayout from "layout/AdminLayout";
import AuthGuard from "utils/route-guard/AuthGuard";
import MainLayout from "layout/MainLayout";

// views
const UserProfile = Loadable(lazy(() => import("views/user/profile")));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const UserRoutes = {
  path: "/user",
  element: (
    <AuthGuard>
      <MainLayout />
    </AuthGuard>
  ),
  children: [
    {
      path: "self",
      element: <UserProfile />,
    },
  ],
};

export default UserRoutes;
