import { lazy } from "react";
import Loadable from "ui-component/Loadable";

const BuyerDashboard = Loadable(lazy(() => import("views/buyer/dashboard")));

const SpotMarketplace = Loadable(
  lazy(() => import("views/buyer/marketplace/spotMarketplace"))
);

const UpcomingMarketplace = Loadable(
  lazy(() => import("views/buyer/marketplace/upcomingMarketplace"))
);

const ViewProjectDetails = Loadable(
  lazy(() =>
    import("views/buyer/marketplace/upcomingMarketplace/projectOverview")
  )
);

const BuyerCarbonCredits = Loadable(
  lazy(() => import("views/buyer/carbonCredits"))
);

const BuyerPaymentDetails = Loadable(
  lazy(() => import("views/buyer/paymentDetails"))
);

const Checkout = Loadable(lazy(() => import("views/buyer/checkout")));

const BuyerRoutes = [
  {
    path: "/dashboard",
    element: <BuyerDashboard />,
  },
  {
    path: "/buyer/marketplace/spotMarketplace",
    element: <SpotMarketplace />,
  },
  {
    path: "/buyer/marketplace/upcomingProjects",
    element: <UpcomingMarketplace />,
  },
  {
    path: "/buyer/marketplace/upcomingProjects/view/:id",
    element: <ViewProjectDetails isBuyer={true} />, // Pass isBuyer prop
  },
  {
    path: "/buyer/carbonCredits",
    element: <BuyerCarbonCredits />,
  },
  {
    path: "/buyer/paymentDetails",
    element: <BuyerPaymentDetails />,
  },
  {
    path: "/buyer/checkout/:listingId",
    element: <Checkout />,
  },
];

export default BuyerRoutes;
