import { useGetProjects } from "api/project";
import useAuth from "hooks/useAuth";
import { createContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FullPageLoader from "ui-component/FullPageLoader";

const initialState = {
  project: null,
};

const ProjectContext = createContext(initialState);

export function ProjectProvider({ children }) {
  const navigate = useNavigate();

  const { projectId } = useParams();

  const { user } = useAuth();

  const { projects, projectsLoading, projectsError } = useGetProjects(user.id);

  const [project, setProject] = useState(initialState.project);

  useEffect(() => {
    if (projects && projects.length > 0) {
      const foundProject = projects.find((p) => p._id === projectId);

      const currentProject = foundProject || projects[0];

      setProject(currentProject);

      if (currentProject._id !== projectId) {
        navigate(`/finance-portal/${currentProject._id}/dashboard`);
      }
    }
  }, [navigate, projectId, projects]);

  // TODO: the project id should be used as value
  const updateProject = (project) => {
    const foundProject = projects.find((p) => p._id === project);
    if (foundProject) {
      setProject(foundProject);
      navigate(`/finance-portal/${project}/dashboard`);
    }
  };

  if (projectsLoading) {
    return <FullPageLoader />;
  }

  if (!project) {
    return <FullPageLoader />;
  }

  return (
    <ProjectContext.Provider
      value={{
        project,
        projects,
        projectsLoading,
        projectsError,
        setProject: updateProject,
      }}
    >
      {children}
    </ProjectContext.Provider>
  );
}

export default ProjectContext;
