import React from "react";
import { CircularProgress, Typography, Grid, CardContent } from "@mui/material";
import MainCard from "ui-component/cards/MainCard";

const LoadingState = () => {
  return (
    <MainCard>
      <CardContent>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: "100vh" }}
        >
          <Grid item>
            <CircularProgress />
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" style={{ marginTop: "16px" }}>
              Loading...
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </MainCard>
  );
};

export default LoadingState;
