import { lazy } from "react";

// project imports
import { ProjectProvider } from "contexts/FinanceProjectContext";
import FinanceLayout from "layout/FinanceLayout";
import Loadable from "ui-component/Loadable";
import AuthGuard from "utils/route-guard/AuthGuard";
import RoleBasedGuard from "utils/route-guard/RoleGuard";

const DashboardPage = Loadable(
  lazy(() => import("views/finance-portal/dashboard"))
);

// maintenance routing
const ApplicationsHomePage = Loadable(
  lazy(() => import("views/finance-portal/application/home"))
);
const EquityPage = Loadable(
  lazy(() => import("views/finance-portal/application/equity"))
);

const DebtPage = Loadable(
  lazy(() => import("views/finance-portal/application/debt"))
);

const FuturesPage = Loadable(
  lazy(() => import("views/finance-portal/application/futures"))
);

const ApplicationsListPage = Loadable(
  lazy(() => import("views/finance-portal/application/list"))
);
const ApplicationsEditPage = Loadable(
  lazy(() => import("views/finance-portal/application/edit"))
);
const DataRoom = Loadable(lazy(() => import("views/finance-portal/data-room")));

const DataRoomList = Loadable(
  lazy(() => import("views/finance-portal/data-room/list"))
);

const DataRoomFolder = Loadable(
  lazy(() => import("views/finance-portal/data-room/list/FolderList"))
);

const RequestsListPage = Loadable(
  lazy(() => import("views/finance-portal/requests/list"))
);

const RequestsViewPage = Loadable(
  lazy(() => import("views/finance-portal/requests/details"))
);

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const FinanceRoutes = {
  path: "/finance-portal/:projectId",
  element: (
    <AuthGuard>
      <ProjectProvider>
        <FinanceLayout />
      </ProjectProvider>
    </AuthGuard>
  ),
  children: [
    {
      path: "dashboard",
      element: <DashboardPage />,
    },
    {
      path: "applications",
      children: [
        {
          path: "equity",
          element: <EquityPage />,
        },
        {
          path: "futures",
          element: <FuturesPage />,
        },
        {
          path: "debt",
          element: <DebtPage />,
        },
        {
          path: "list",
          element: <ApplicationsListPage />,
        },
        {
          path: "edit/:id",
          element: <ApplicationsEditPage />,
        },
        {
          path: "home",
          element: <ApplicationsHomePage />,
        },
      ],
    },
    {
      path: "data-room",
      children: [
        {
          index: true,
          element: (
            // <RoleBasedGuard roles={["proponent"]} hasContent>
            <DataRoomList />
            // </RoleBasedGuard>
          ),
        },
        {
          path: "list",
          element: <DataRoom />,
        },
        {
          path: "folder/:id",
          element: <DataRoomFolder />,
        },
      ],
    },
    {
      path: "requests",
      children: [
        {
          path: "list",
          element: <RequestsListPage />,
        },
        {
          path: ":id",
          element: <RequestsViewPage />,
        },
      ],
    },
  ],
};

export default FinanceRoutes;
