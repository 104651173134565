import { useRoutes } from "react-router-dom";

// routes
import AuthenticationRotes from "./AuthenticationRoutes";
import LoginRoutes from "./LoginRoutes";
import MainRoutes from "./MainRoutes";
import FinanceRoutes from "./FinanceRoutes";
import CommunityRoutes from "./CommunityRoutes";
import ProponentRoutes from "./ProponentRoutes";
import PublicRoutes from "./PublicRoutes";
import UserRoutes from "./UserRoutes";
import BuyerRoutes from "./BuyerRoutes";
import DeveloperRoutes from "./DeveloperRoutes";

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    LoginRoutes,
    AuthenticationRotes,
    MainRoutes,
    FinanceRoutes,
    CommunityRoutes,
    ProponentRoutes,
    PublicRoutes,
    UserRoutes,
    BuyerRoutes,
    DeveloperRoutes,
  ]);
}
