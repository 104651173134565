import PropTypes from "prop-types";

// material-ui
import {
  Avatar,
  Box,
  MenuItem,
  Select,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

// project imports
import LAYOUT_CONST from "constant";
import useConfig from "hooks/useConfig";
import LogoSection from "../LogoSection";
import CommunitySection from "./CommunitySection";
import MobileSection from "./MobileSection";
import NotificationSection from "./NotificationSection";
import ProfileSection from "./ProfileSection";
import HomeSection from "./HomeSection";

// assets
import { IconMenu2 } from "@tabler/icons";
import { useContext } from "react";
import ProjectContext from "contexts/ProjectContext";
import useProject from "hooks/useProject";
import SelectProjectSection from "./SelectProjectSection";

// ==============================|| MAIN NAVBAR / HEADER ||============================== //

const Header = ({ handleDrawerToggle }) => {
  const theme = useTheme();
  const { project, setProject } = useProject();

  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const { layout } = useConfig();

  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 228,
          display: "flex",
          [theme.breakpoints.down("md")]: {
            width: "auto",
          },
        }}
      >
        <Box
          component="span"
          sx={{ display: { xs: "none", md: "block" }, flexGrow: 1 }}
        >
          <LogoSection />
        </Box>

        {layout === LAYOUT_CONST.VERTICAL_LAYOUT ||
        (layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && matchDownMd) ? (
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              overflow: "hidden",
              transition: "all .2s ease-in-out",
              background:
                theme.palette.mode === "dark"
                  ? theme.palette.dark.main
                  : theme.palette.secondary.light,
              color:
                theme.palette.mode === "dark"
                  ? theme.palette.secondary.main
                  : theme.palette.secondary.dark,
              "&:hover": {
                background:
                  theme.palette.mode === "dark"
                    ? theme.palette.secondary.main
                    : theme.palette.secondary.dark,
                color:
                  theme.palette.mode === "dark"
                    ? theme.palette.secondary.light
                    : theme.palette.secondary.light,
              },
            }}
            onClick={handleDrawerToggle}
            color="inherit"
          >
            <IconMenu2 stroke={1.5} size="20px" />
          </Avatar>
        ) : null}
      </Box>

      {/* header search */}
      <SelectProjectSection />

      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ flexGrow: 1 }} />

      {/* live customization & localization */}
      {/* <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                <LocalizationSection />
            </Box> */}

      {/* community section */}
      <HomeSection />

      {/* notification & profile */}
      {/* <NotificationSection /> */}
      <ProfileSection />

      {/* mobile header */}
      <Box sx={{ display: { xs: "block", sm: "none" } }}>
        <MobileSection />
      </Box>
    </>
  );
};

Header.propTypes = {
  handleDrawerToggle: PropTypes.func,
};

export default Header;
