// third-party
import { FormattedMessage } from "react-intl";

// assets
import { IconHomeEco, IconGavel, IconBriefcase } from "@tabler/icons";

// constant
const icons = {
  IconHomeEco,
  IconGavel,
  IconBriefcase,
};
const greenStyle = { color: "green" };
const Community = {
  id: "Community Portal",
  title: (
    <FormattedMessage id="Community Portal" defaultMessage="Community Portal" />
  ),
  type: "group",
  children: [
    {
      id: "community_home",
      title: <FormattedMessage id="Home" defaultMessage="Home" />,
      type: "item",
      url: "/community-portal/home",
      icon: icons.IconHomeEco,
      breadcrumbs: false,
    },
    {
      id: "community_fpic",
      title: (
        <FormattedMessage
          id="FPIC Guidelines"
          defaultMessage="FPIC Guidelines"
        />
      ),
      type: "item",
      url: "/community-portal/fpic/faqs",
      icon: icons.IconGavel,
      breadcrumbs: false,
    },
    {
      id: "community_engagement",
      title: (
        <FormattedMessage
          id="Community Archive"
          defaultMessage="Community Archive"
        />
      ),
      type: "collapse",
      breadcrumbs: false,
      icon: icons.IconBriefcase,
      defaultOpen: true,
      children: [
        {
          id: "community_engagement_reports",
          title: (
            <FormattedMessage
              id="Create Report"
              defaultMessage="Create Report"
            />
          ),
          type: "item",
          url: "/community-portal/archive/reports/create",
          breadcrumbs: false,
        },
        {
          id: "community_engagement_details",
          title: (
            <FormattedMessage id="View Reports" defaultMessage="View Reports" />
          ),
          type: "item",
          url: "/community-portal/archive/reports/list/",
          breadcrumbs: false,
        },
      ],
    },
  ],
};

const menuItems = {
  items: [Community],
};

export default menuItems;
