import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// project imports
import useAuth from "hooks/useAuth";
import FullPageLoader from "ui-component/FullPageLoader";

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }) => {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();

  const [checked, setChecked] = useState(false);

  const check = useCallback(() => {
    if (!isLoggedIn) {
      const searchParams = new URLSearchParams({
        returnTo: window.location.pathname,
      }).toString();
      navigate(`/login?${searchParams}`, { replace: true });
    } else {
      setChecked(true);
    }
  }, [isLoggedIn, navigate]);

  useEffect(() => {
    check();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!checked) {
    return null;
  }

  return children;
};

AuthGuard.propTypes = {
  children: PropTypes.node,
};

export default function AuthGuardWrapper({ children }) {
  const { loading } = useAuth();

  return (
    <>{loading ? <FullPageLoader /> : <AuthGuard> {children}</AuthGuard>}</>
  );
}
