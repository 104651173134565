import PropTypes from "prop-types";
import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

// project imports
import useAuth from "hooks/useAuth";
import { DASHBOARD_PATH } from "config";
import { useSearchParams } from "hooks/use-search-params";

// ==============================|| GUEST GUARD ||============================== //

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */

const GuestGuard = ({ children }) => {
  const { isLoggedIn } = useAuth();
  const navigate = useNavigate();
  const searchParams = useSearchParams();
  const returnTo = searchParams.get("returnTo") || DASHBOARD_PATH;

  const check = useCallback(() => {
    if (isLoggedIn) {
      navigate(returnTo, { replace: true });
    }
  }, [isLoggedIn, navigate, returnTo]);

  useEffect(() => {
    check();
  }, [check]);

  return children;
};

GuestGuard.propTypes = {
  children: PropTypes.node,
};

export default GuestGuard;
