import { lazy } from "react";
import Loadable from "ui-component/Loadable";

const DeveloperDashboard = Loadable(
  lazy(() => import("views/developer/dashboard"))
);

const ListDeveloperProjects = Loadable(
  lazy(() => import("views/developer/projects/list"))
);

const ViewProjectDetails = Loadable(
  lazy(() => import("views/developer/projects/projectDetails"))
);

const AddMarketplaceListing = Loadable(
  lazy(() => import("views/developer/marketplace/addListing"))
);

const ViewMarketplaceListings = Loadable(
  lazy(() => import("views/developer/marketplace/viewListings"))
);

const InstanceDetails = Loadable(
  lazy(() => import("views/developer/marketplace/viewListings/InstanceDetails"))
);

const ViewPurchaseRequests = Loadable(
  lazy(() => import("views/developer/marketplace/viewPurchaseRequests"))
);

const DeveloperRoutes = [
  // dashboard route
  {
    path: "/dashboard",
    element: <DeveloperDashboard />,
  },
  // project routes
  {
    path: "/developer/projects/list",
    element: <ListDeveloperProjects />,
  },
  {
    path: "/developer/projects/view/:id",
    element: <ViewProjectDetails />,
  },
  // marketplace routes
  {
    path: "/developer/marketplace/new/listing",
    element: <AddMarketplaceListing />,
  },
  {
    path: "/developer/marketplace/view/listings",
    element: <ViewMarketplaceListings />,
  },
  {
    path: "/developer/marketplace/listings/:listingId/instances/:instanceId",
    element: <InstanceDetails />,
  },
  {
    path: "/developer/marketplace/view/purchaseRequests",
    element: <ViewPurchaseRequests />,
  },

  // // stakeholders routes
  // {
  //   path: "/proponent/stakeholders/create",
  //   element: <CreateProjectStakeholders />,
  // },
  // {
  //   path: "/proponent/stakeholders/list",
  //   element: <ListProjectStakehoders />,
  // },
];

export default DeveloperRoutes;
