import { FormattedMessage } from "react-intl";

import {
  IconDashboard,
  IconFilePlus,
  IconFolder,
  IconInbox,
  IconList,
  IconMail,
  IconMessageCircle,
  IconNewSection,
  IconReportMoney,
} from "@tabler/icons";

const Finance = {
  id: "Finance",
  title: <FormattedMessage id="Finance" defaultMessage="Finance" />,
  icon: IconMail,
  type: "group",
  children: [
    {
      id: "finance_dashboard",
      title: <FormattedMessage id="dashboard" defaultMessage="Dashboard" />,
      type: "item",
      url: (id) => `/finance-portal/${id}/dashboard`,
      icon: IconDashboard,
      breadcrumbs: false,
    },
    {
      id: "finance_applications",
      title: (
        <FormattedMessage id="Applications" defaultMessage="Applications" />
      ),
      icon: IconReportMoney,
      type: "collapse",
      breadcrumbs: false,

      children: [
        {
          id: "finance_applications_create",
          title: (
            <FormattedMessage
              id=" New Application"
              defaultMessage=" New Application"
            />
          ),
          type: "item",
          icon: IconNewSection,
          url: (id) => `/finance-portal/${id}/applications/home`,

          breadcrumbs: false,
        },
        {
          id: "finance_applications_list",
          title: (
            <FormattedMessage
              id=" List and Manage"
              defaultMessage="Applications List"
            />
          ),
          type: "item",
          icon: IconList,
          url: (id) => `/finance-portal/${id}/applications/list`,

          breadcrumbs: false,
        },
      ],
    },
    {
      id: "finance_requests",
      title: <FormattedMessage id="Requests" defaultMessage="Requests" />,
      type: "item",
      url: (id) => `/finance-portal/${id}/requests/list`,
      breadcrumbs: false,
      icon: IconMessageCircle,
    },
    {
      id: "finance_data_room",
      title: <FormattedMessage id="Data Room" defaultMessage="Data Room" />,
      type: "item",
      url: (id) => `/finance-portal/${id}/data-room`,
      icon: IconFolder,
      breadcrumbs: false,
    },
  ],
};

const menuItems = {
  items: [Finance],
};

export default menuItems;
