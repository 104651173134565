import { useMemo, useEffect, useCallback } from "react";
import useSWR, { mutate } from "swr";
import axiosServices, { convertToFormData, endpoints } from "utils/axios";
import { useNavigate } from "react-router-dom";

const fetcher = (url) => axiosServices.get(url).then((res) => res.data);

// TODO : the token should determine which users projects to return
export function useGetProjects(userId) {
  const endpoint = `/projects-service/projects/list?userId=${userId}`;

  const { data, isLoading, error, isValidating } = useSWR(
    endpoints.projects.list,
    async function (url) {
      const res = await axiosServices.get(
        `${process.env.REACT_APP_BASE_URL}/projects-service/projects/list?userId=${userId}`
      );
      return res.data;
    }
  );

  const memoizedValue = useMemo(
    () => ({
      projects: data,
      projectsLoading: isLoading,
      projectsError: error,
    }),
    [data, isLoading, error]
  );
  return memoizedValue;
}

const debounce = (func, wait) => {
  let timeout;
  return (...args) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
};

const useDebouncedHistory = (project) => {
  const navigate = useNavigate();

  const updateHistory = useCallback(
    debounce((newState) => {
      try {
        const newUrl = `/proponent/projects/view/${newState.id}`;
        navigate(newUrl, { replace: true });
      } catch (e) {
        console.error("Error updating history state:", e);
      }
    }, 300),
    [navigate]
  );

  useEffect(() => {
    if (project?.id) {
      updateHistory(project);
    }
  }, [project, updateHistory]);
};

// Fn to get a single project by ID
export function useGetProjectById(id) {
  const endpoint = `/projects-service/projects/view/${id}`;

  const { data, error, isValidating } = useSWR(endpoint, fetcher, {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
    onErrorRetry: (error, key, option, revalidate, { retryCount }) => {
      if (retryCount >= 3) return;
      if (error.status === 404) return;
      setTimeout(() => revalidate({ retryCount: retryCount + 1 }), 5000);
    },
  });

  // Use the debounced history update
  useDebouncedHistory(data);

  return useMemo(
    () => ({
      project: data,
      isLoading: isValidating,
      error,
    }),
    [data, isValidating, error]
  );
}

export async function updateProject(id, projectData) {
  const endpoint = `/projects-service/projects/${id}`;
  try {
    const response = await axiosServices.put(endpoint, projectData);
    mutate(endpoint);
    return {
      success: true,
      data: response.data.data,
    };
  } catch (error) {
    return {
      success: false,
      error: error.response?.data?.message || error.message,
    };
  }
}

export async function updateProjectDetails(id, projectData) {
  const endpoint = `/projects-service/projects/details/${id}`;
  try {
    const response = await axiosServices.put(endpoint, projectData);
    mutate(endpoint);
    return {
      success: true,
      data: response.data,
    };
  } catch (error) {
    console.error("Update project details error:", error);
    return {
      success: false,
      error: error.response?.data?.message || error.message,
    };
  }
}

export async function createDocument(docData) {
  const endpoint = "/projects-service/documents/upload";
  try {
    const response = await axiosServices.post(endpoint, docData);
    return {
      success: true,
      data: response.data.data,
    };
  } catch (error) {
    return {
      success: false,
      error: error.response?.data?.message || error.message,
    };
  }
}

export const useDeleteProject = () => {
  const deleteProject = async (projectId) => {
    const endpoint = `/projects-service/projects/${projectId}`;
    try {
      await axiosServices.delete(endpoint);
      mutate("/projects-service/projects/list");
    } catch (error) {
      console.error("Failed to delete project:", error);
      throw error;
    }
  };
  return deleteProject;
};

export const uploadNDA = async (url, { arg: { data } }) => {
  const endpoint = `/projects-service/projects/${data.id}/nda`;
  const response = await axiosServices.put(endpoint, convertToFormData(data));
  return response.data;
};

export function useGetUpcomingProjects() {
  const endpoint = "/projects-service/projects/upcoming";

  const { data, isLoading, error, isValidating } = useSWR(endpoint, fetcher, {
    revalidateOnFocus: false,
  });

  const memoizedValue = useMemo(
    () => ({
      projects: data,
      isLoading,
      error,
    }),
    [data, isLoading, error]
  );
  return memoizedValue;
}

export const useAddStakeholder = () => {
  const addStakeholder = async (projectId, stakeholderData) => {
    try {
      const response = await axiosServices.patch(
        `/projects-service/projects/stakeholder/${projectId}`,
        stakeholderData
      );
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message || error.message);
    }
  };

  return { addStakeholder };
};

export function useGetDeveloperProjects(email) {
  const endpoint = `/projects-service/projects/developer?email=${email}`;

  const { data, isLoading, error, isValidating } = useSWR(
    endpoint,
    async function (url) {
      const res = await axiosServices.get(url);
      return res.data;
    }
  );

  const memoizedValue = useMemo(
    () => ({
      projects: data,
      projectsLoading: isLoading,
      projectsError: error,
    }),
    [data, isLoading, error]
  );

  return memoizedValue;
}

export async function updateProjectMonitoringPeriods(id, monitoringPeriods) {
  const endpoint = `/projects-service/projects/${id}/monitoring-periods`;
  try {
    const response = await axiosServices.put(endpoint, { monitoringPeriods });
    mutate(endpoint);
    return {
      success: true,
      data: response.data.data,
    };
  } catch (error) {
    return {
      success: false,
      error: error.response?.data?.message || error.message,
    };
  }
}
