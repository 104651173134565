import investor from "./investor";
import { useAuth } from "hooks/useAuth";
import proponent from "./proponent";
import buyer from "./buyer";
import developer from "./developer";

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [proponent, investor, buyer, developer],
};

export default menuItems;
