// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
  IconApps,
  IconUserCheck,
  IconBasket,
  IconMessages,
  IconLayoutKanban,
  IconMail,
  IconCalendar,
  IconNfc,
  IconShieldLock,
  IconLayoutGridAdd,
  IconUsers,
  IconClipboardList,
  IconDashboard,
  IconUserPlus,
  IconUserSearch,
  IconFolderPlus,
  IconTableOptions,
  IconAlignBoxLeftMiddle,
  IconCreditCard,
  IconCards,
  IconHomeDollar,
  IconFileInvoice,
  IconHomeStats,
  IconDiscountCheck,
  IconCoins,
  IconArchive,
  IconGasStation,
  IconInfoSquareRounded,
} from "@tabler/icons";

// constant
const icons = {
  IconApps,
  IconUserCheck,
  IconBasket,
  IconMessages,
  IconLayoutKanban,
  IconMail,
  IconCalendar,
  IconNfc,
  IconShieldLock,
  IconLayoutGridAdd,
  IconUsers,
  IconClipboardList,
  IconDashboard,
  IconUserPlus,
  IconUserSearch,
  IconFolderPlus,
  IconTableOptions,
  IconAlignBoxLeftMiddle,
  IconCreditCard,
  IconCards,
  IconHomeDollar,
  IconFileInvoice,
  IconHomeStats,
  IconDiscountCheck,
  IconCoins,
  IconArchive,
  IconGasStation,
  IconInfoSquareRounded,
};

// ==============================|| ADMIN MENU ITEMS ||============================== //

const admin = {
  id: "admin",
  title: <FormattedMessage id="Admin" defaultMessage="Admin" />,
  icon: icons.IconApps,
  type: "group",
  children: [
    {
      id: "admin_dashboard",
      title: <FormattedMessage id="Dashboard" defaultMessage="Dashboard" />,
      type: "item",
      url: "/admin/dashboard",
      icon: icons.IconDashboard,
      breadcrumbs: false,
    },
    {
      id: "admin_users_mgt",
      title: <FormattedMessage id="Users" defaultMessage="Users Management" />,
      type: "collapse",
      url: "/admin/users",
      icon: icons.IconUsers,
      breadcrumbs: false,
      children: [
        {
          id: "admin_users_mgt",
          title: <FormattedMessage id="Users" defaultMessage="Users" />,
          type: "item",
          icon: icons.IconUserPlus,
          url: "/admin/users",
          breadcrumbs: false,
        },
        {
          id: "admin_roles_mgt",
          title: <FormattedMessage id="Roles" defaultMessage="Roles" />,
          type: "item",
          url: "/admin/roles",
          icon: icons.IconUserCheck,
          breadcrumbs: false,
        },
        {
          id: "admin_permissions_mgt",
          title: (
            <FormattedMessage id="Permissions" defaultMessage="Permissions" />
          ),
          type: "item",
          url: "/admin/permissions",
          icon: icons.IconShieldLock,
          breadcrumbs: false,
        },
      ],
    },
    {
      id: "admin_projects_mgt",
      title: <FormattedMessage id="Projects" defaultMessage="Projects" />,
      type: "item",
      url: "/admin/projects",
      icon: icons.IconClipboardList,
      breadcrumbs: false,
    },
    {
      id: "fpic_reports",
      title: (
        <FormattedMessage id="FPIC Reports" defaultMessage="FPIC Reports" />
      ),
      type: "item",
      url: "/admin/fpic-reports",
      icon: icons.IconArchive,
      breadcrumbs: false,
    },
    {
      id: "admin_interest_forms",
      title: (
        <FormattedMessage id="Interest Forms" defaultMessage="Interest Forms" />
      ),
      type: "item",
      url: "/admin/interest-forms",
      icon: icons.IconInfoSquareRounded,
      breadcrumbs: false,
    },
  ],
};

const menuItems = {
  items: [admin],
};

export default menuItems;