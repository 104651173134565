import { Link as RouterLink } from 'react-router-dom';

// material-ui
import { Link, Stack, Typography } from '@mui/material';

// project imports
import { DASHBOARD_PATH } from 'config';
import Logo from "ui-component/SidebarLogo";

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
    <Stack direction='column' spacing={2}>
    <Link component={RouterLink} to={DASHBOARD_PATH}>
        <Logo />
    </Link>
    </Stack>
);

export default LogoSection;
