import PropTypes from "prop-types";

// material-ui
import { Divider, Grid, Typography } from "@mui/material";

// third-party
import Chart from "react-apexcharts";

// project imports
import useConfig from "hooks/useConfig";
import MainCard from "ui-component/cards/MainCard";

// =========================|| USERS DISTRO CHART CARD ||========================= //

const RegistryDistributionChartCard = ({ chartData }) => {
  const { rtlLayout } = useConfig();

  return (
    <MainCard>
      <Grid container direction="column" spacing={1}>
        <Grid item>
          <Typography variant="h4">Registry Distribution</Typography>
        </Grid>
        <Grid item>
          <Divider sx={{ my: 2 }} />
        </Grid>
        <Grid
          item
          sx={{
            "& .apexcharts-legend-text": {
              marginLeft: rtlLayout ? "8px" : "initial",
            },
          }}
        >
          <Chart {...chartData} />
        </Grid>
      </Grid>
    </MainCard>
  );
};

RegistryDistributionChartCard.propTypes = {
  chartData: PropTypes.object,
};

export default RegistryDistributionChartCard;
