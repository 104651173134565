// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
  IconApps,
  IconUserCheck,
  IconBasket,
  IconMessages,
  IconLayoutKanban,
  IconMail,
  IconCalendar,
  IconNfc,
  IconClipboardList,
  IconTrees,
  IconHeartHandshake,
  IconDashboard,
  IconUsers,
  IconAlignBoxLeftMiddle,
  IconBoxMultiple,
  IconWallet,
  IconPremiumRights,
  IconCreditCard,
  IconFilePencil,
  IconReport,
  IconUserPlus,
  IconFiles,
  IconBuildingStore,
  IconUpload,
  IconFileText,
  IconListDetails,
} from "@tabler/icons";

// constant
const icons = {
  IconApps,
  IconUserCheck,
  IconBasket,
  IconMessages,
  IconLayoutKanban,
  IconMail,
  IconCalendar,
  IconNfc,
  IconClipboardList,
  IconTrees,
  IconHeartHandshake,
  IconDashboard,
  IconUsers,
  IconAlignBoxLeftMiddle,
  IconBoxMultiple,
  IconWallet,
  IconPremiumRights,
  IconCreditCard,
  IconFilePencil,
  IconReport,
  IconUserPlus,
  IconFiles,
  IconBuildingStore,
  IconUpload,
  IconFileText,
  IconListDetails,
};

// ==============================|| PROPONENT MENU ITEMS ||============================== //

const proponent = {
  id: "proponent",
  title: <FormattedMessage id="proponent" defaultMessage="Project Proponent" />,
  icon: icons.IconApps,
  type: "group",
  permissions: ["create:applications"],
  roles: ["proponent"],
  children: [
    {
      id: "proponent_dashboard",
      title: <FormattedMessage id="Dashboard" defaultMessage="Dashboard" />,
      type: "item",
      url: "/dashboard",
      icon: icons.IconDashboard,
      breadcrumbs: false,
      roles: ["proponent"],
    },
    {
      id: "proponent_projects",
      title: <FormattedMessage id="Projects" defaultMessage="Projects" />,
      type: "collapse",
      breadcrumbs: false,
      icon: icons.IconTrees,
      roles: ["proponent"],
      children: [
        {
          id: "onboarding",
          title: (
            <FormattedMessage id="Add Project" defaultMessage="Add Project" />
          ),
          type: "item",
          icon: icons.IconHeartHandshake,
          url: "/proponent/projects/onboarding",
          breadcrumbs: false,
          roles: ["proponent"],
        },
        {
          id: "project_list",
          title: (
            <FormattedMessage
              id="Projects List"
              defaultMessage="Projects List"
            />
          ),
          type: "item",
          url: "/proponent/projects/list",
          icon: icons.IconClipboardList,
          breadcrumbs: false,
          roles: ["proponent"],
        },
        // {
        //   id: "project_documents",
        //   title: (
        //     <FormattedMessage
        //       id="Project Documents"
        //       defaultMessage="Project Documents"
        //     />
        //   ),
        //   type: "item",
        //   url: "/proponent/documents/list",
        //   icon: icons.IconFiles,
        //   breadcrumbs: false,
        // },
      ],
    },
    {
      id: "marketplace_listings",
      title: (
        <FormattedMessage
          id="MarketplaceListings"
          defaultMessage="Marketplace Listings"
        />
      ),
      type: "collapse",
      breadcrumbs: false,
      icon: icons.IconBuildingStore,
      roles: ["proponent"],
      children: [
        {
          id: "add_listing",
          title: (
            <FormattedMessage id="Add Listing" defaultMessage="Add Listing" />
          ),
          type: "item",
          icon: icons.IconUpload,
          url: "/proponent/marketplace/new/listing",
          breadcrumbs: false,
          roles: ["proponent"],
        },
        {
          id: "view_listings",
          title: (
            <FormattedMessage
              id="View Listings"
              defaultMessage="View Listings"
            />
          ),
          type: "item",
          url: "/proponent/marketplace/view/listings",
          icon: icons.IconFileText,
          breadcrumbs: false,
          roles: ["proponent"],
        },
        {
          id: "view_purchases",
          title: (
            <FormattedMessage
              id="View Purchase Requests"
              defaultMessage="View Purchase Requests"
            />
          ),
          type: "item",
          url: "/proponent/marketplace/view/purchaseRequests",
          icon: icons.IconListDetails,
          breadcrumbs: false,
          roles: ["proponent"],
        },
      ],
    },
    {
      id: "project_stakeholders",
      title: (
        <FormattedMessage id="Stakeholders" defaultMessage="Stakeholders" />
      ),
      type: "collapse",
      breadcrumbs: false,
      icon: icons.IconUsers,
      children: [
        {
          id: "create_stakeholder",
          title: (
            <FormattedMessage
              id="Add Stakeholder"
              defaultMessage="Add Stakeholder"
            />
          ),
          type: "item",
          icon: icons.IconUserPlus,
          url: "/proponent/stakeholders/create",
          breadcrumbs: false,
        },
        {
          id: "stakeholder_list",
          title: (
            <FormattedMessage
              id="Stakeholder List"
              defaultMessage="Stakeholder List"
            />
          ),
          type: "item",
          url: "/proponent/stakeholders/list",
          icon: icons.IconUserCheck,
          breadcrumbs: false,
        },
      ],
    },
  ],
};

export default proponent;
