// material-ui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

// project imports
import { gridSpacing } from "store/constant";

// assets
import statusImage from "assets/images/img-status.png";

// ==============================|| ERROR PAGE ||============================== //

const ErrorPage = ({ header, message }) => {
  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: "100vh" }}
      spacing={gridSpacing}
    >
      <Grid item xs={12}>
        <Box sx={{ width: { xs: 350, sm: 396 } }}>
          <img
            src={statusImage}
            alt="error"
            style={{ height: "100%", width: "100%" }}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Stack
          justifyContent="center"
          alignItems="center"
          spacing={gridSpacing}
          sx={{ p: 1.5 }}
        >
          <Typography variant="h1">{header}</Typography>
          <Typography variant="body2" align="center">
            {message}
          </Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default ErrorPage;
