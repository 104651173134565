// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
  IconApps,
  IconUserCheck,
  IconBasket,
  IconMessages,
  IconLayoutKanban,
  IconMail,
  IconCalendar,
  IconNfc,
  IconClipboardList,
  IconTrees,
  IconHeartHandshake,
  IconDashboard,
  IconUsers,
  IconAlignBoxLeftMiddle,
  IconBoxMultiple,
  IconWallet,
  IconPremiumRights,
  IconCreditCard,
  IconFilePencil,
  IconReport,
  IconUserPlus,
  IconFiles,
  IconBuildingStore,
  IconUpload,
  IconFileText,
  IconListDetails,
} from "@tabler/icons";

// constant
const icons = {
  IconApps,
  IconUserCheck,
  IconBasket,
  IconMessages,
  IconLayoutKanban,
  IconMail,
  IconCalendar,
  IconNfc,
  IconClipboardList,
  IconTrees,
  IconHeartHandshake,
  IconDashboard,
  IconUsers,
  IconAlignBoxLeftMiddle,
  IconBoxMultiple,
  IconWallet,
  IconPremiumRights,
  IconCreditCard,
  IconFilePencil,
  IconReport,
  IconUserPlus,
  IconFiles,
  IconBuildingStore,
  IconUpload,
  IconFileText,
  IconListDetails,
};

// ==============================|| DEVELOPER MENU ITEMS ||============================== //

const developer = {
  id: "developer",
  title: <FormattedMessage id="developer" defaultMessage="Project Developer" />,
  icon: icons.IconApps,
  type: "group",
  //   permissions: ["create:applications"],
  roles: ["developer"],
  children: [
    {
      id: "developer_dashboard",
      title: <FormattedMessage id="Dashboard" defaultMessage="Dashboard" />,
      type: "item",
      url: "/dashboard",
      icon: icons.IconDashboard,
      breadcrumbs: false,
      roles: ["developer"],
    },
    {
      id: "developer_projects",
      title: <FormattedMessage id="Projects" defaultMessage="Projects" />,
      type: "collapse",
      breadcrumbs: false,
      icon: icons.IconTrees,
      roles: ["developer"],
      children: [
        {
          id: "project_list",
          title: (
            <FormattedMessage
              id="Projects List"
              defaultMessage="Projects List"
            />
          ),
          type: "item",
          url: "/developer/projects/list",
          icon: icons.IconClipboardList,
          breadcrumbs: false,
          roles: ["developer"],
        },
      ],
    },
    {
      id: "marketplace_listings",
      title: (
        <FormattedMessage
          id="MarketplaceListings"
          defaultMessage="Marketplace Listings"
        />
      ),
      type: "collapse",
      breadcrumbs: false,
      icon: icons.IconBuildingStore,
      roles: ["developer"],
      children: [
        {
          id: "add_listing",
          title: (
            <FormattedMessage id="Add Listing" defaultMessage="Add Listing" />
          ),
          type: "item",
          icon: icons.IconUpload,
          url: "/developer/marketplace/new/listing",
          breadcrumbs: false,
          roles: ["developer"],
        },
        {
          id: "view_listings",
          title: (
            <FormattedMessage
              id="View Listings"
              defaultMessage="View Listings"
            />
          ),
          type: "item",
          url: "/developer/marketplace/view/listings",
          icon: icons.IconFileText,
          breadcrumbs: false,
          roles: ["developer"],
        },
        // {
        //   id: "view_purchases",
        //   title: (
        //     <FormattedMessage
        //       id="View Purchase Requests"
        //       defaultMessage="View Purchase Requests"
        //     />
        //   ),
        //   type: "item",
        //   url: "/developer/marketplace/view/purchaseRequests",
        //   icon: icons.IconListDetails,
        //   breadcrumbs: false,
        //   roles: ["developer"],
        // },
      ],
    },
  ],
};

export default developer;
