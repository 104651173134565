import { memo } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import { Typography, useMediaQuery } from "@mui/material";

// project imports
import menuItemsData from "menu-items";
import NavGroup from "./NavGroup";
import LAYOUT_CONST from "constant";
import { HORIZONTAL_MAX_ITEM } from "config";
import useConfig from "hooks/useConfig";
import useAuth from "hooks/useAuth";

// ==============================|| SIDEBAR MENU LIST ||============================== //
const filterMenuItems = (items, role) => {
  return items.filter((item) => {
    if (item.roles && !item.roles.includes(role)) {
      return false;
    }
    if (item.children) {
      item.children = filterMenuItems(item.children, role);
    }
    return true;
  });
};

const MenuList = () => {
  const theme = useTheme();
  const { user } = useAuth();
  const role = user.roles[0];
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const { layout } = useConfig();

  const filteredItems = filterMenuItems(menuItemsData.items, role);

  // last menu-item to show in horizontal menu bar
  const lastItem =
    layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd
      ? HORIZONTAL_MAX_ITEM
      : null;

  let lastItemIndex = filteredItems.length - 1;
  let remItems = [];
  let lastItemId;

  if (lastItem && lastItem < filteredItems.length) {
    lastItemId = filteredItems[lastItem - 1].id;
    lastItemIndex = lastItem - 1;
    remItems = filteredItems
      .slice(lastItem - 1, filteredItems.length)
      .map((item) => ({
        title: item.title,
        elements: item.children,
      }));
  }

  const navItems = filteredItems.slice(0, lastItemIndex + 1).map((item) => {
    switch (item.type) {
      case "group":
        if (
          item.permissions &&
          !item.permissions.some((permission) =>
            user.permissions.includes(permission)
          )
        ) {
          return null;
        }
        return (
          <NavGroup
            key={item.id}
            item={item}
            lastItem={lastItem}
            remItems={remItems}
            lastItemId={lastItemId}
          />
        );
      default:
        return (
          <Typography key={item.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return <>{navItems}</>;
};

export default memo(MenuList);
