
// material-ui
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

// project imports
import { gridSpacing } from "store/constant";
import AnimateButton from "ui-component/extended/AnimateButton";

// assets
import LoadingButton from "@mui/lab/LoadingButton";
import { ListItem, ListItemText } from "@mui/material";
import statusImage from "assets/images/img-status.png";
import useAuth from "hooks/useAuth";

// ==============================|| ERROR PAGE ||============================== //

const StatusPage = ({ status }) => {
  const { logout, isLoading } = useAuth();
  function statusMessage(status) {
    if (status === "pending") {
      return "Your account is pending approval";
    } else if (status === "rejected") {
      return "Your account creation request has been rejected";
    } else if (status === "banned") {
      return "Your account has been banned";
    }
  }
  function statusNextSteps(status) {
    if (status === "pending") {
      return (
        <Grid item xs={6} justifyContent={"center"} alignItems={"center"}>
          <ol>
            <li>
              <ListItem>
                <ListItemText primary="Our team will review your account and get back to you shortly." />
              </ListItem>
            </li>
            <li>
              <ListItem>
                <ListItemText primary="You can expect approval within 3 to 5 working days." />
              </ListItem>
            </li>
            <li>
              <ListItem>
                <ListItemText primary="Once approved, you will receive an email notification, and you can then proceed to use the platform" />
              </ListItem>
            </li>
          </ol>
        </Grid>
      );
    } else if (status === "rejected") {
      return (
        <Grid item xs={6} justifyContent={"center"} alignItems={"center"}>
          <Typography variant="body1" text-align="center">
            After careful consideration, we regret to inform you that we are
            unable to approve your access request at this time. This decision
            may be based on a variety of factors related to eligibility
            criteria, verification of provided information, or alignment with
            our platform's objectives.
          </Typography>
        </Grid>
      );
    } else if (status === "banned") {
      return (
        <Grid item xs={6} justifyContent={"center"} alignItems={"center"}>
          <Typography variant="body1" text-align="center">
            Effective immediately, you will no longer have access to the Verst
            Carbon Platform. This decision is made after a thorough review and
            is necessary to prevent further policy violations.
          </Typography>
        </Grid>
      );
    }
  }

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: "100vh" }}
      spacing={gridSpacing}
    >
      <Grid item xs={12}>
        <Box sx={{ width: { xs: 350, sm: 396 } }}>
          <img
            src={statusImage}
            alt="mantis"
            style={{ height: "100%", width: "100%" }}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Stack
          justifyContent="center"
          alignItems="center"
          spacing={gridSpacing}
          sx={{ p: 1.5 }}
        >
          <Typography variant="h1">{statusMessage(status)}</Typography>

          {statusNextSteps(status)}

          {/* TODO: Contact us Page */}
          <AnimateButton>
            <LoadingButton
              variant="contained"
              size="large"
              onClick={logout}
              loading={isLoading}
            >
              Log Out
            </LoadingButton>
          </AnimateButton>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default StatusPage;
