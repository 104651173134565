// material-ui
import { useTheme } from '@mui/material/styles';
import { Typography } from '@mui/material';

//  if you want to use image instead of <svg> uncomment following.
import logo from 'assets/images/logos/verst_logo.svg';

// ==============================|| LOGO ||============================== //

const Logo = () => {
    const theme = useTheme();

    return (
        <img style={{ width: 200, height: 53, position: 'relative' }} src={logo} alt="Verst Logo" />
        // <Typography fontWeight="bold" fontSize="24px" color={theme.palette.secondary.main}>
        //     VERST CARBON
        // </Typography>
    );
};

export default Logo;
