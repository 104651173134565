import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "hooks/useAuth";
import ProponentDashboard from "views/proponent/dashboard";
import BuyerDashboard from "views/buyer/dashboard";
import DeveloperDashboard from "views/developer/dashboard";
import MaintenanceError from "views/pages/maintenance/Error";

const Dashboard = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/login", { replace: true });
      return;
    }
  }, [user, navigate]);

  if (!user) {
    return null; // or a loading spinner if you prefer
  }


  const role = user?.roles[0];

  console.log("role details", role);

  if (role === "proponent") {
    return <ProponentDashboard />;
  } else if (role === "Buyer") {
    return <BuyerDashboard />;
  } else if (role === "developer") {
    return <DeveloperDashboard />;
  } else {
    // Handle unexpected role or return a 404 component
    return <MaintenanceError />;
  }
};

export default Dashboard;
