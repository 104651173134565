import { useContext } from "react";

const { default: ProjectContext } = require("contexts/ProjectContext");


function useProject (){

    const context = useContext(ProjectContext);

    if (!context){
        throw new Error('context must be used within a provider');
    }
    return context
}

export default useProject;