import { createRoot } from "react-dom/client";

// third party
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

// project imports
import App from "App";
import { BASE_PATH } from "config";
import { store } from "store";
import * as serviceWorker from "serviceWorker";
import reportWebVitals from "reportWebVitals";
import { ConfigProvider } from "contexts/ConfigContext";

// style + assets
import "assets/scss/style.scss";

import { SWRConfig } from "swr";

// ==============================|| REACT DOM RENDER  ||============================== //

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <Provider store={store}>
    <SWRConfig
      value={{
        onErrorRetry: (error, key, config, revalidate, { retryCount }) => {
          if (error.status === 404) return;

          if (retryCount >= 10) return;

          setTimeout(() => revalidate({ retryCount }), 5000);
        },
      }}
    >
      <ConfigProvider>
        <BrowserRouter basename={BASE_PATH}>
          
          <App />
        </BrowserRouter>
      </ConfigProvider>
    </SWRConfig>
  </Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
