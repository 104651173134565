/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from "react";
import GoogleMapReact from "google-map-react";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { Button, Box, Card, CardContent, Typography } from "@mui/material";
import LocationMarkerIcon from "assets/images/icons/location-icon.png";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const AllProjectsMap = ({ projects }) => {
  const navigate = useNavigate();
  const [activeProject, setActiveProject] = useState(null);

  const handleMarkerClick = (project) => {
    setActiveProject(project);
  };

  const handleDialogClose = () => {
    setActiveProject(null);
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        activeProject &&
        !document.getElementById("project-dialog").contains(e.target)
      ) {
        setActiveProject(null);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [activeProject]);

  const defaultCenter = { lat: 0, lng: 0 };
  const defaultZoom = 2;

  return (
    <Box height="50vh" width="100%" position="relative">
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_GOOGLE_MAPS_API_KEY }}
        defaultCenter={defaultCenter}
        defaultZoom={defaultZoom}
        options={{
          gestureHandling: "cooperative",
          mapTypeId: "terrain",
          mapTypeControl: true,
          streetViewControl: true,
          fullscreenControl: false,
        }}
      >
        {projects?.map((project) =>
          project?.projectLocation?.map((loc) => (
            <Marker
              key={loc._id}
              lat={parseFloat(loc.latitude)}
              lng={parseFloat(loc.longitude)}
              name={loc.name}
              onClick={() => handleMarkerClick(project)}
            />
          ))
        )}
      </GoogleMapReact>
      {activeProject && (
        <Box
          id="project-dialog"
          position="absolute"
          top={20}
          right={20}
          zIndex={999}
        >
          <Card variant="outlined">
            <CardContent>
              <CloseIcon
                sx={{
                  cursor: "pointer",
                  position: "absolute",
                  top: 15,
                  right: 15,
                }}
                onClick={handleDialogClose}
              />
              <Typography variant="h4" sx={{ py: 1 }}>
                {activeProject.projectName}
              </Typography>
              <Typography variant="body2">
                {activeProject.projectDescription}
              </Typography>
              <Button
                variant="outlined"
                color="primary"
                sx={{ mt: 1 }}
                onClick={() =>
                  navigate(
                    `/proponent/projects/view/${activeProject._id}?tabLabel=about-project`
                  )
                }
              >
                View More
              </Button>
            </CardContent>
          </Card>
        </Box>
      )}
    </Box>
  );
};

const Marker = ({ name, onClick }) => (
  <Box
    onClick={onClick}
    role="button"
    tabIndex={0}
    sx={{
      cursor: "pointer",
      transform: "translate(-50%, -100%)",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    }}
  >
    <LocationOnIcon color="primary" fontSize="large" />
    <Typography variant="caption">{name}</Typography>
  </Box>
);

export default AllProjectsMap;
