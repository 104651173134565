import { lazy } from "react";

// project imports
import AuthGuard from "utils/route-guard/AuthGuard";
import RoleBasedGuard from "utils/route-guard/RoleGuard";

import MainLayout from "layout/MainLayout";
import Loadable from "ui-component/Loadable";

// import proponent views
import ProponentRoutes from "./ProponentRoutes";
import BuyerRoutes from "./BuyerRoutes";
import DeveloperRoutes from "./DeveloperRoutes";
import StatusGuard from "utils/route-guard/StatusGuard";
import Dashboard from "./Dashboard";

// sample page routing
const SamplePage = Loadable(lazy(() => import("views/sample-page")));
const KYC = Loadable(lazy(() => import("views/pages/authentication/KYC")));
const Projects = Loadable(lazy(() => import("views/applications/list")));
const ApplicationDetails = Loadable(
  lazy(() => import("views/applications/details"))
);
const Requests = Loadable(
  lazy(() => import("views/applications/list/Requests"))
);
const ApprovedRequests = Loadable(
  lazy(() => import("views/applications/list/ApprovedRequests"))
);
const ProjectDetails = Loadable(
  lazy(() => import("views/applications/details/project"))
);
const ProfileView = Loadable(lazy(() => import("views/user/profile")));

const DashboardPage = Loadable(lazy(() => import("views/dashboards")));
// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: (
    <AuthGuard>
      <StatusGuard>
        <MainLayout />
      </StatusGuard>
    </AuthGuard>
  ),
  children: [
    {
      path: "/profile",
      element: <ProfileView />,
    },
    {
      path: "/sample-page",
      element: <SamplePage />,
    },
    {
      path: "/kyc",
      element: <KYC />,
    },
    {
      path: "/applications",
      element: <Projects />,
    },
    {
      path: "/applications/:id",
      element: <ApplicationDetails />,
    },
    {
      path: "/dashboard",
      element: <DashboardPage />,
    },
    ...ProponentRoutes.map((route) => ({
      ...route,
      element: (
        <RoleBasedGuard roles={["proponent"]}>{route.element}</RoleBasedGuard>
      ),
    })),
    ...BuyerRoutes.map((route) => ({
      ...route,
      element: (
        <RoleBasedGuard roles={["buyer"]}>{route.element}</RoleBasedGuard>
      ),
    })),
    ...DeveloperRoutes.map((route) => ({
      ...route,
      element: (
        <RoleBasedGuard roles={["developer"]}>{route.element}</RoleBasedGuard>
      ),
    })),
    {
      path: "/requests",
      element: <Requests />,
    },
    {
      path: "/projects",
      element: <ApprovedRequests />,
    },
    {
      path: `/projects/:id`,
      element: <ProjectDetails />,
    },
    {
      path: `/dashboard`,
      element: <Dashboard />,
    },
  ],
};

export default MainRoutes;
