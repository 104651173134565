import PropTypes from "prop-types";

// project imports
import useAuth from "hooks/useAuth";
import StatusPage from "ui-component/StatusPage";

// ==============================|| GUEST GUARD ||============================== //

/**
 * Guest guard for routes having no auth required
 * @param {PropTypes.node} children children element/node
 */

const StatusGuard = ({ children }) => {
  const { user } = useAuth();
  const { status } = user;

  if (status === "approved") {
    return children;
  }

  return <StatusPage status={status} />;
};

StatusGuard.propTypes = {
  children: PropTypes.node,
};

export default StatusGuard;
