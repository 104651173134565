/* eslint-disable no-underscore-dangle */
import React, { useState } from "react";
import PropTypes from "prop-types";

// material-ui
import {
  Box,
  Button,
  CardActions,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

// third party
import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
import MainCard from "ui-component/cards/MainCard";
import { useNavigate } from "react-router-dom";
import { PROJECT_MILESTONES, PROJECT_TYPES } from "utils/Constants";
import useAuth from "hooks/useAuth";

// assets
import VisibilityTwoToneIcon from "@mui/icons-material/VisibilityTwoTone";

// =========================|| PROJECTS TABLE CARD ||========================= //

const ProjectsTableCard = ({
  title,
  projects,
  onMilestoneChange,
  onTypeChange,
}) => {
  const history = useNavigate();
  const [selectedMilestone, setSelectedMilestone] = useState("All");
  const [selectedType, setSelectedType] = useState("All");
  const { user } = useAuth();

  const handleViewOnClick = (project) => {
    const baseProjectUrl = process.env.REACT_APP_PROJECT_URL;
    const milestone = project?.milestone;
    const projectId = project?._id;

    if (user?.role === "proponent" || user?.role === "developer") {
      if (milestone === "Creation") {
        console.log("Navigating to onboard project");
        window.location.href = `${baseProjectUrl}/onboard-project?projectId=${projectId}`;
      } else {
        const rolePath = user?.role === "proponent" ? "proponent" : "developer";
        console.log(`Navigating to ${rolePath} project view`);
        history(`/${rolePath}/projects/view/${projectId}`);
      }
    }
  };

  const handleMilestoneChange = (event) => {
    setSelectedMilestone(event.target.value);
    if (onMilestoneChange) {
      onMilestoneChange(event.target.value);
    }
  };

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
    onTypeChange(event.target.value);
  };

  return (
    <MainCard
      title={
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Typography variant="h4">{title}</Typography>
          {/* <Box display="flex" gap={2}>
            <FormControl
              variant="outlined"
              size="small"
              sx={{ m: 0, minWidth: 120 }}
            >
              <InputLabel id="project-type-select-label">Type</InputLabel>
              <Select
                labelId="project-type-select-label"
                id="project-type-select"
                value={selectedType}
                onChange={handleTypeChange}
                label="Type"
              >
                <MenuItem value="All">All</MenuItem>
                {Object.keys(PROJECT_TYPES).map((type) => (
                  <MenuItem key={type} value={type}>
                    {type}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl
              variant="outlined"
              size="small"
              sx={{ m: 0, minWidth: 120 }}
            >
              <InputLabel id="milestone-select-label">Milestone</InputLabel>
              <Select
                labelId="milestone-select-label"
                id="milestone-select"
                value={selectedMilestone}
                onChange={handleMilestoneChange}
                label="Milestone"
              >
                <MenuItem value="All">All</MenuItem>
                {PROJECT_MILESTONES.map((milestone) => (
                  <MenuItem key={milestone} value={milestone}>
                    {milestone}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box> */}
        </Box>
      }
      content={false}
    >
      <PerfectScrollbar style={{ height: 345, padding: 0 }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Project Name</TableCell>
                <TableCell>Project Type</TableCell>
                <TableCell>Project Milestone</TableCell>
                <TableCell>Project Country</TableCell>
                <TableCell>Project Status</TableCell>
                <TableCell>Date Created</TableCell>
                <TableCell>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {projects?.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={10} align="center">
                    No projects available at the moment
                  </TableCell>
                </TableRow>
              ) : (
                projects?.slice(0, 9).map((project, index) => (
                  <TableRow
                    onClick={() => handleViewOnClick(project)}
                    key={project?._id}
                    role="button"
                    style={{ cursor: "pointer" }}
                  >
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{project?.projectName}</TableCell>
                    <TableCell>{project?.projectType}</TableCell>
                    <TableCell>{project?.milestone}</TableCell>
                    <TableCell>
                      {Array.isArray(project?.projectLocation)
                        ? project?.projectLocation
                            .map((loc) => `${loc.name} `)
                            .join(", ")
                        : JSON.parse(project?.projectLocation || "[]")
                            .map((loc) => `${loc.name} `)
                            .join(", ")}
                    </TableCell>
                    <TableCell>{project?.projectStatus}</TableCell>
                    <TableCell>
                      {new Date(project?.createdAt).toLocaleDateString()}
                    </TableCell>

                    <TableCell align="start" sx={{ pr: 3 }}>
                      <IconButton
                        color="primary"
                        size="large"
                        onClick={() => handleViewOnClick(project)}
                      >
                        <VisibilityTwoToneIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </PerfectScrollbar>

      <Divider />
      <CardActions sx={{ justifyContent: "flex-end" }}>
        <Button
          variant="outlined"
          size="small"
          onClick={() => history("/developer/projects/list")}
        >
          View Projects
        </Button>
      </CardActions>
    </MainCard>
  );
};
ProjectsTableCard.propTypes = {
  title: PropTypes.string,
  projects: PropTypes.array,
  onMilestoneChange: PropTypes.func,
  onTypeChange: PropTypes.func,
};

export default ProjectsTableCard;
