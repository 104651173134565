import React from "react";
import { Box, CircularProgress } from "@mui/material";

function FullPageLoader() {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh" // Makes sure the Box takes at least the full viewport height
    >
      <CircularProgress />
    </Box>
  );
}

export default FullPageLoader;
