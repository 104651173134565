import { lazy } from "react";
import Loadable from "ui-component/Loadable";
import RoleGuard from "utils/route-guard/RoleGuard";

// import proponent views
// dashboard views
const ProponentDashboard = Loadable(
  lazy(() => import("views/proponent/dashboard"))
);
// project views
const OnboardProponentProjects = Loadable(
  lazy(() => import("views/proponent/projects/onboarding"))
);
const ListProponentProjects = Loadable(
  lazy(() => import("views/proponent/projects/list"))
);

const ViewProjectDetails = Loadable(
  lazy(() => import("views/proponent/projects/projectDetails"))
);

const ViewProjectDocuments = Loadable(
  lazy(() => import("views/proponent/documents/list"))
);

// marketplace views
const AddMarketplaceListing = Loadable(
  lazy(() => import("views/proponent/marketplace/addListing"))
);

const ViewMarketplaceListings = Loadable(
  lazy(() => import("views/proponent/marketplace/viewListings"))
);

const InstanceDetails = Loadable(
  lazy(() => import("views/proponent/marketplace/viewListings/InstanceDetails"))
);

const ViewPurchaseRequests = Loadable(
  lazy(() => import("views/proponent/marketplace/viewPurchaseRequests"))
);

// stakeholders views
const CreateProjectStakeholders = Loadable(
  lazy(() => import("views/proponent/stakeholders/create"))
);
const ListProjectStakehoders = Loadable(
  lazy(() => import("views/proponent/stakeholders/list"))
);

const ProponentRoutes = [
  // dashboard route
  {
    path: "/dashboard",
    element: <ProponentDashboard />,
  },
  // project routes
  {
    path: "/proponent/projects/onboarding",
    element: <OnboardProponentProjects />,
  },
  {
    path: "/proponent/projects/list",
    element: <ListProponentProjects />,
  },
  {
    path: "/proponent/projects/view/:id",
    element: <ViewProjectDetails />,
  },
  {
    path: "/proponent/documents/list",
    element: <ViewProjectDocuments />,
  },
  // marketplace routes
  {
    path: "/proponent/marketplace/new/listing",
    element: <AddMarketplaceListing />,
  },
  {
    path: "/proponent/marketplace/view/listings",
    element: <ViewMarketplaceListings />,
  },
  {
    path: "/proponent/marketplace/listings/:listingId/instances/:instanceId",
    element: <InstanceDetails />,
  },
  {
    path: "/proponent/marketplace/view/purchaseRequests",
    element: <ViewPurchaseRequests />,
  },

  // stakeholders routes
  {
    path: "/proponent/stakeholders/create",
    element: <CreateProjectStakeholders />,
  },
  {
    path: "/proponent/stakeholders/list",
    element: <ListProjectStakehoders />,
  },
];

export default ProponentRoutes;
