import { Navigate, useLocation } from "react-router-dom";
import PropTypes from "prop-types";

import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import useAuth from "hooks/useAuth";

// ----------------------------------------------------------------------

export default function RoleBasedGuard({ hasContent, roles, children, sx }) {
  // Logic here to get current user role
  const { user } = useAuth();
  const location = useLocation();

  const defaultPath = user?.roles?.includes("proponent")
    ? "/dashboard"
    : user?.roles?.includes("buyer")
    ? "/dashboard"
    : user?.roles?.includes("developer")
    ? "/dashboard"
    : "/";

  if (
    typeof roles !== "undefined" &&
    !user.roles?.some((role) => roles.includes(role))
  ) {
    return hasContent ? (
      <Container sx={{ textAlign: "center", ...sx }}>
        <Typography variant="h3" sx={{ mb: 2 }}>
          Permission Denied
        </Typography>
        <Typography sx={{ color: "text.secondary" }}>
          You do not have permission to access this page
        </Typography>
      </Container>
    ) : (
      <Navigate to={defaultPath} state={{ from: location }} />
    );
  }

  return <> {children} </>;
}

RoleBasedGuard.propTypes = {
  children: PropTypes.node,
  hasContent: PropTypes.bool,
  roles: PropTypes.arrayOf(PropTypes.string),
  sx: PropTypes.object,
};
