import { useMemo } from "react";
import useSWR from "swr";
import axiosServices from "utils/axios";

// Fetcher function for useSWR
const fetcher = (url) => axiosServices.get(url).then((res) => res.data);

export async function createListingInstance(listingData) {
  const endpoint = "/projects-service/marketplace/listing";
  try {
    const response = await axiosServices.post(endpoint, listingData);
    return {
      success: true,
      data: response.data.data,
    };
  } catch (error) {
    return {
      success: false,
      error: error.response?.data?.message || error.message,
    };
  }
}

export async function addInstanceToListing(listingId, instanceData) {
  const endpoint = `/projects-service/marketplace/listing/${listingId}/instance`;
  try {
    const response = await axiosServices.post(endpoint, instanceData);
    return {
      success: true,
      data: response.data.data,
    };
  } catch (error) {
    return {
      success: false,
      error: error.response?.data?.message || error.message,
    };
  }
}

export async function updateInstanceStatus(listingId, instanceId, newStatus) {
  const endpoint = `/projects-service/marketplace/listing/${listingId}/instance/${instanceId}/status`;
  try {
    const response = await axiosServices.patch(endpoint, { status: newStatus });
    return {
      success: true,
      data: response.data.data,
    };
  } catch (error) {
    return {
      success: false,
      error: error.response?.data?.message || error.message,
    };
  }
}

export async function updateInstanceCredits(listingId, instanceId, newCredits) {
  const endpoint = `/projects-service/marketplace/listing/${listingId}/instance/${instanceId}/credits`;
  try {
    const response = await axiosServices.patch(endpoint, {
      carbonCredits: newCredits,
    });
    return {
      success: true,
      data: response.data.data,
    };
  } catch (error) {
    return {
      success: false,
      error: error.response?.data?.message || error.message,
    };
  }
}

export function UseGetAllListings(projectId) {
  const endpoint =
    projectId && projectId !== "All"
      ? `/projects-service/marketplace/allListings?projectId=${projectId}`
      : `/projects-service/marketplace/allListings?projectId=${projectId}`;

  const { data, error, isValidating } = useSWR(endpoint, fetcher);

  return useMemo(
    () => ({
      listings: data?.data,
      isLoading: isValidating,
      error,
    }),
    [data, isValidating, error]
  );
}

export function useGetApprovedListings() {
  const endpoint = `/projects-service/marketplace/approvedListings`;

  const { data, error, isValidating } = useSWR(endpoint, fetcher);

  return {
    listings: data?.data,
    isLoading: isValidating,
    error,
  };
}

export function useGetListingById(id) {
  const endpoint = id
    ? `/projects-service/marketplace/listings/view/${id}`
    : null;

  const { data, error, isValidating } = useSWR(endpoint, fetcher);

  return useMemo(
    () => ({
      listing: data,
      isLoading: isValidating,
      isError: error,
    }),
    [data, isValidating, error]
  );
}

export function useGetInstancesByListingId(listingId) {
  const { data, error, isValidating } = useSWR(
    listingId
      ? `/projects-service/marketplace/listings/${listingId}/instances`
      : null,
    fetcher
  );

  return {
    instances: data?.data || [],
    isLoading: !error && !data,
    isError: error,
    isValidating,
  };
}

export async function createPurchaseRequest(purchaseData) {
  const endpoint = "/projects-service/marketplace/purchase-requests";
  try {
    const response = await axiosServices.post(endpoint, purchaseData);
    return {
      success: true,
      data: response.data,
    };
  } catch (error) {
    return {
      success: false,
      error: error.response?.data?.message || error.message,
    };
  }
}

export function useGetPurchaseRequestsByEmail(email) {
  const endpoint = email
    ? `/projects-service/marketplace/purchase-requests?email=${email}`
    : null;

  const { data, error, isValidating } = useSWR(endpoint, fetcher);

  return {
    purchaseRequests: data,
    isLoading: isValidating,
    isError: error,
  };
}

export const useGetPurchaseRequests = (email) => {
  const { data, error } = useSWR(
    `/projects-service/marketplace/purchase-requests?email=${email}`,
    fetcher
  );

  return {
    data: data?.data || [],
    isLoading: !error && !data,
    isError: error,
  };
};

export const useGetProponentPurchaseRequests = (proponentId) => {
  const { data, error } = useSWR(
    `/projects-service/marketplace/proponent-purchase-requests?proponentId=${proponentId}`,
    fetcher
  );

  return {
    data: data?.data || [],
    isLoading: !error && !data,
    isError: error,
  };
};
