/* eslint-disable no-underscore-dangle */
import { Link } from "react-router-dom";
import useAuth from "hooks/useAuth";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Button,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";

// project imports
import { gridSpacing } from "store/constant";
import Loader from "ui-component/Loader";
import DashCountCard from "ui-component/cards/DashCountCard";
import PurchasesTableCard from "./PurchasesTableCard";
import RegistryDistributionChartCard from "./RegistryDistributionChartCard";

import { useGetPurchaseRequests } from "api/marketplace";

// assets
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DescriptionTwoToneIcon from "@mui/icons-material/DescriptionTwoTone";
import WalletIcon from "@mui/icons-material/Wallet";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";

// ==============================|| BUYER DASHBOARD ||============================== //

// number formatting
const formatNumber = (number) => {
  return new Intl.NumberFormat("en-US").format(number);
};

// currency formatting
const formatCurrency = (number) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(number);
};

const prepareRegistryChartData = (purchaseRequests) => {
  const registryCount = {};

  purchaseRequests.forEach((request) => {
    const registry = request.instanceId?.registry;
    if (registry) {
      if (!registryCount[registry]) {
        registryCount[registry] = 0;
      }
      registryCount[registry]++;
    }
  });

  const distributionData = Object.keys(registryCount).map((registry) => ({
    registry,
    count: registryCount[registry],
  }));

  return {
    height: 400,
    type: "donut",
    options: {
      chart: {
        id: "registry-distribution-chart",
      },
      dataLabels: {
        enabled: true,
      },
      labels: distributionData.map((item) => item.registry),
      legend: {
        show: true,
        position: "bottom",
        fontFamily: "inherit",
        labels: {
          colors: "inherit",
        },
        itemMargin: {
          horizontal: 10,
          vertical: 15,
        },
      },
    },
    series: distributionData.map((item) => item.count),
  };
};

const Dashboard = () => {
  const theme = useTheme();
  const { user } = useAuth();
  const { data: purchaseRequests, isLoading } = useGetPurchaseRequests(
    user.email
  );
  const matchDownSM = useMediaQuery(theme.breakpoints.down("sm"));
  const matchDownMD = useMediaQuery(theme.breakpoints.down("lg"));
  const spacingMD = matchDownMD ? 1 : 1.5;

  console.log("purchase-request->", purchaseRequests);

  if (isLoading) {
    return <Loader />;
  }

  const totalCarbonCredits = purchaseRequests.reduce((acc, request) => {
    try {
      return acc + (request.instanceId?.carbonCredits || 0);
    } catch (error) {
      console.error(
        `Error calculating carbon credits for request ${request._id}:`,
        error
      );
      return acc;
    }
  }, 0);

  const totalCarbonCreditsValue = purchaseRequests.reduce((acc, request) => {
    try {
      const credits = request.instanceId?.carbonCredits || 0;
      const price = request.instanceId?.averagePrice || 0;
      return acc + credits * price;
    } catch (error) {
      console.error(
        `Error calculating carbon credits value for request ${request._id}:`,
        error
      );
      return acc;
    }
  }, 0);

  const registryChartData = prepareRegistryChartData(purchaseRequests);

  return (
    <Grid container spacing={gridSpacing}>
      <Grid item xs={12}>
        <Grid
          container
          alignItems="center"
          justifyContent="space-between"
          spacing={matchDownMD ? 0.5 : 2}
        >
          <Grid item>
            <Stack direction="row" alignItems="center" spacing={1}>
              <Typography variant="h4">Dashboard</Typography>
              <IconButton size="large">
                <ArrowForwardIosIcon
                  sx={{
                    width: "0.875rem",
                    height: "0.875rem",
                    fontWeight: 500,
                    color: "grey.500",
                  }}
                />
              </IconButton>
            </Stack>
          </Grid>
          <Grid item>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={matchDownSM ? 0.5 : spacingMD}
            >
              <Button
                variant="contained"
                color="secondary"
                component={Link}
                to="/buyer/marketplace/spotMarketplace"
              >
                Marketplace
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Divider sx={{ borderColor: "grey.400" }} />
      </Grid>

      <Grid item xs={12} lg={4} md={6}>
        <DashCountCard
          primary="Total Carbon Credits Purchased"
          secondary={formatNumber(totalCarbonCredits)}
          iconPrimary={DescriptionTwoToneIcon}
          color={theme.palette.info.main}
        />
      </Grid>

      <Grid item xs={12} lg={4} md={6}>
        <DashCountCard
          primary="Total Value of Carbon Credits"
          secondary={formatCurrency(totalCarbonCreditsValue)}
          iconPrimary={AccountBalanceWalletIcon}
          color={theme.palette.purple.main}
        />
      </Grid>

      <Grid item xs={12} lg={4} md={6}>
        <DashCountCard
          primary="Total Purchase Requests"
          secondary={purchaseRequests.length.toString()}
          iconPrimary={WalletIcon}
          color={theme.palette.success.dark}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <PurchasesTableCard
          title="Purchase Requests"
          purchaseRequests={purchaseRequests}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <RegistryDistributionChartCard chartData={registryChartData} />
      </Grid>
    </Grid>
  );
};

export default Dashboard;
