import { useEffect, useMemo, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";

// material-ui
import { styled, useTheme } from "@mui/material/styles";
import {
  Alert,
  AppBar,
  Box,
  Button,
  Container,
  CssBaseline,
  Toolbar,
  useMediaQuery,
} from "@mui/material";

// project imports
import Header from "./Header";
import Sidebar from "./Sidebar";
import HorizontalBar from "./HorizontalBar";
import Breadcrumbs from "ui-component/extended/Breadcrumbs";

import navigation from "menu-items";
import LAYOUT_CONST from "constant";
import useConfig from "hooks/useConfig";
import { drawerWidth } from "store/constant";
import { openDrawer } from "store/slices/menu";
import { useDispatch, useSelector } from "store";

// assets
import { IconChevronRight } from "@tabler/icons";
import useAuth from "hooks/useAuth";

// styles
const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open, layout }) => ({
    ...theme.typography.mainContent,
    ...(!open && {
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.shorter + 200,
      }),
      [theme.breakpoints.up("md")]: {
        marginLeft:
          layout === LAYOUT_CONST.VERTICAL_LAYOUT
            ? -(drawerWidth - 72)
            : "20px",
        width: `calc(100% - ${drawerWidth}px)`,
        marginTop: layout === LAYOUT_CONST.HORIZONTAL_LAYOUT ? 135 : 88,
      },
      [theme.breakpoints.down("md")]: {
        marginLeft: "20px",
        width: `calc(100% - ${drawerWidth}px)`,
        padding: "16px",
        marginTop: 88,
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: "10px",
        width: `calc(100% - ${drawerWidth}px)`,
        padding: "16px",
        marginRight: "10px",
        marginTop: 88,
      },
    }),
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.shorter + 200,
      }),
      marginLeft: layout === LAYOUT_CONST.HORIZONTAL_LAYOUT ? "20px" : 0,
      marginTop: layout === LAYOUT_CONST.HORIZONTAL_LAYOUT ? 135 : 88,
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      width: `calc(100% - ${drawerWidth}px)`,
      [theme.breakpoints.up("md")]: {
        marginTop: layout === LAYOUT_CONST.HORIZONTAL_LAYOUT ? 135 : 88,
      },
      [theme.breakpoints.down("md")]: {
        marginLeft: "20px",
        marginTop: 88,
      },
      [theme.breakpoints.down("sm")]: {
        marginLeft: "10px",
        marginTop: 88,
      },
    }),
  })
);

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const path = useLocation().pathname;
  const { user } = useAuth();
  const theme = useTheme();

  console.log(path);

  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));

  const dispatch = useDispatch();
  const { drawerOpen } = useSelector((state) => state.menu);
  const { drawerType, container, layout } = useConfig();

  const [open, setOpen] = useState(
    drawerType === LAYOUT_CONST.DEFAULT_DRAWER && drawerOpen
  );

  useEffect(() => {
    if (drawerType === LAYOUT_CONST.DEFAULT_DRAWER) {
      setOpen(true);
      dispatch(openDrawer(true));
    } else {
      setOpen(false);
      dispatch(openDrawer(false));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerType]);

  const handleDrawerToggle = () => {
    setOpen(!open);
    dispatch(openDrawer(!open));
  };

  useEffect(() => {
    if (drawerType === LAYOUT_CONST.DEFAULT_DRAWER) {
      dispatch(openDrawer(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (matchDownMd) {
      setOpen(true);
      dispatch(openDrawer(true));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchDownMd]);

  useEffect(() => {
    if (open !== drawerOpen) setOpen(drawerOpen);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drawerOpen]);

  const condition = layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd;

  const header = useMemo(
    () => (
      <Toolbar sx={{ p: condition ? "10px" : "16px" }}>
        <Header open={open} handleDrawerToggle={handleDrawerToggle} />
      </Toolbar>
    ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [layout, matchDownMd, open]
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {/* header */}
      <AppBar
        position="fixed"
        elevation={0}
        sx={{
          bgcolor: "background.default",
          transition: drawerOpen ? theme.transitions.create("width") : "none",
        }}
      >
        {header}
      </AppBar>

      {/* horizontal menu-list bar */}
      {layout === LAYOUT_CONST.HORIZONTAL_LAYOUT && !matchDownMd && (
        <HorizontalBar />
      )}

      {/* drawer */}
      {(layout === LAYOUT_CONST.VERTICAL_LAYOUT || matchDownMd) && (
        <Sidebar open={open} handleDrawerToggle={handleDrawerToggle} />
      )}

      {/* main content */}
      <Main theme={theme} open={drawerOpen} layout={layout}>
        <Container maxWidth={container ? "lg" : false}>
          {/* breadcrumb */}
          <Breadcrumbs
            separator={IconChevronRight}
            navigation={navigation}
            icon
            title
            rightAlign
          />
          {user?.KYC?.status === "in-progress" && (
            <Alert severity="info" sx={{ mb: 3 }}>
              Your KYC verification is in progress. You will be notified once
              the verification is complete.
            </Alert>
          )}

          {user?.KYC?.status === "pending" && path !== "/kyc" && (
            <Alert severity="warning" sx={{ mb: 3 }}>
              Your KYC verification is pending. Please complete the necessary
              steps to proceed.
              <Button
                component={Link}
                to="/kyc"
                sx={{
                  ml: 1,
                  bgcolor: "orange.main",
                  color: "orange.light",
                  "&:hover": {
                    bgcolor: "orange.light",
                    color: "orange.dark",
                  },
                }}
              >
                Start KYC
              </Button>
            </Alert>
          )}
          {user?.KYC?.status === "rejected" && (
            <Alert severity="error" sx={{ mb: 3 }}>
              Your KYC verification has been rejected. If you believe this is an
              error, please contact our support team for assistance.
            </Alert>
          )}

          <Outlet />
        </Container>
      </Main>
    </Box>
  );
};

export default MainLayout;
