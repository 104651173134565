/* eslint-disable no-underscore-dangle */
import React from "react";
import PropTypes from "prop-types";

// material-ui
import {
  Box,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

// third party
import PerfectScrollbar from "react-perfect-scrollbar";

// project imports
import MainCard from "ui-component/cards/MainCard";
import VisibilityTwoToneIcon from "@mui/icons-material/VisibilityTwoTone";

// =========================|| PURCHASE REQUESTS TABLE CARD ||========================= //

// format the date
const formatDate = (dateString) => {
  const options = { day: "numeric", month: "long", year: "numeric" };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

// number formatting
const formatNumber = (number) => {
  return new Intl.NumberFormat("en-US").format(number);
};

const PurchasesTableCard = ({ title, purchaseRequests }) => {
  return (
    <MainCard
      title={
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
        >
          <Typography variant="h4">{title}</Typography>
        </Box>
      }
      content={false}
    >
      <PerfectScrollbar style={{ height: 410, padding: 0 }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Listing ID</TableCell>
                <TableCell>Price ($)</TableCell>
                <TableCell>Carbon Credits Amount</TableCell>
                <TableCell>Registry</TableCell>
                <TableCell>Registry Account</TableCell>
                <TableCell>Date Created</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {purchaseRequests.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={9} align="center">
                    No purchase requests found
                  </TableCell>
                </TableRow>
              ) : (
                purchaseRequests.map((request, index) => (
                  <TableRow key={request._id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{request.instanceId?._id}</TableCell>
                    <TableCell>{request.instanceId?.averagePrice}</TableCell>
                    <TableCell>
                      {formatNumber(request.instanceId?.carbonCredits)}
                    </TableCell>
                    <TableCell>{request.instanceId?.registry}</TableCell>
                    <TableCell>
                      {request.instanceId?.registrationNumber}
                    </TableCell>
                    <TableCell>
                      {formatDate(request.instanceId?.updatedAt)}
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </PerfectScrollbar>
    </MainCard>
  );
};

PurchasesTableCard.propTypes = {
  title: PropTypes.string,
  purchaseRequests: PropTypes.array,
};

export default PurchasesTableCard;
