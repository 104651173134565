import axios from "../../utils/axios";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

const initialState = {
  status: "idle",
  error: null,
  projects: [],
  project: {},
};

export const fetchProjects = createAsyncThunk(
  "projects/fetchProjects",
  async (userId, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/projects/list`,
        { params: { userId } } // Pass userId as a query parameter
      );
      return response.data; // Adjust based on your actual API response structure
    } catch (error) {
      return rejectWithValue(error.toString());
    }
  }
);

export const getProject = createAsyncThunk(
  "projects/getProject",
  async (id) => {
    const response = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/projects/view/${id}`
    );
    console.log("Project data:", response.data); // Log the response data
    return response.data;
  }
);

// Slice
const projectsSlice = createSlice({
  name: "projects",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProjects.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProjects.fulfilled, (state, action) => {
        state.status = "idle";
        state.projects = action.payload;
        state.error = null;
      })
      .addCase(fetchProjects.rejected, (state, action) => {
        state.status = "idle";
        state.error = action.error.message;
      })
      .addCase(getProject.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getProject.fulfilled, (state, action) => {
        state.status = "idle";
        state.project = action.payload;
        state.error = null;
      })
      .addCase(getProject.rejected, (state, action) => {
        state.status = "idle";
        state.error = action.error.message;
      });
  },
});

export default projectsSlice.reducer;
